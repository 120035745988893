import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { setAuthToken } from '../utils/axiosInstance';

function Login() {
    const [encryptedUsername, setEncryptedUsername] = useState(null);
    const [encryptedPassword, setEncryptedPassword] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const encryptData = (data) => {
        const secretKey = process.env.REACT_APP_SHARED_ENCRYPTION_SECRET;
        if (!secretKey) {
            console.error('Shared encryption secret is not defined');
            return null;
        }

        const key = CryptoJS.enc.Hex.parse(secretKey);
        const iv = CryptoJS.lib.WordArray.random(16);

        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return {
            iv: iv.toString(CryptoJS.enc.Hex),
            data: encrypted.toString()
        };
    };

    const handleUsernameChange = (e) => {
        const encrypted = encryptData(e.target.value);
        //console.log('Encrypted username:', encrypted);
        setEncryptedUsername(encrypted);
    };

    const handlePasswordChange = (e) => {
        const encrypted = encryptData(e.target.value);
        //console.log('Encrypted password:', encrypted);
        setEncryptedPassword(encrypted);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!encryptedUsername || !encryptedPassword) {
            setError('Please enter both username and password.');
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/auth/login`,
                {
                    username: encryptedUsername.data,
                    username_iv: encryptedUsername.iv,
                    password: encryptedPassword.data,
                    password_iv: encryptedPassword.iv
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data && response.data.token) {
                setAuthToken(response.data.token); // Use the new setAuthToken function
                navigate('/dashboard');
            } else {
                setError('Invalid response from server');
            }
        } catch (err) {
            if (err.response) {
                setError(err.response.data.message || 'An error occurred. Please try again.');
            } else if (err.request) {
                setError('No response received from server. Please try again.');
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-secondary">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h1 className="text-2xl font-bold text-dark mb-4">Windfall VR Admin Panel</h1>
                {error && <div className="bg-red-200 text-red-800 p-2 mb-4 rounded">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="username" className="block text-dark font-bold mb-2">
                            Username
                        </label>
                        <input
                            type="text"
                            id="username"
                            onChange={handleUsernameChange}
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-dark font-bold mb-2">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            onChange={handlePasswordChange}
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    >
                        Continue
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;
