import React, { useState, useEffect } from 'react';

const MAX_LENGTH = 255; // Maximum length for VARCHAR(255)

function AddEvent({ onClose, onSave, editEvent }) {
    const [event, setEvent] = useState({
        name: '',
        description: '',
        notes: '',
        practice_sessions: 0,
        duration: 0,
        average_cart_value: 0,
        map_url: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (editEvent) {
            // Exclude map_url when updating an existing event
            const { map_url, ...eventWithoutMapUrl } = editEvent;
            setEvent(eventWithoutMapUrl);
        }
    }, [editEvent]);

    const validateField = (name, value) => {
        if (typeof value === 'string' && value.length > MAX_LENGTH) {
            return `${name} must be ${MAX_LENGTH} characters or less`;
        }
        if ((name === 'practice_sessions' || name === 'duration' || name === 'average_cart_value') && value < 0) {
            return `${name} cannot be negative`;
        }
        return null;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newValue = e.target.type === 'number' ? parseFloat(value) : value;
        setEvent(prevState => ({
            ...prevState,
            [name]: newValue
        }));

        // Clear error when user starts typing
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };

    const handleSubmit = (e) => {
    e.preventDefault();

    // Create a copy of the event object
    const eventData = { ...event };

    // Remove logo_url and map_url if we are editing an event
    if (editEvent) {
        delete eventData.logo_url;
        delete eventData.map_url;
    }

    // Debugging: Log eventData to ensure logo_url and map_url are removed
    console.log('eventData before validation:', eventData);

    // Validate all fields that are provided in eventData
    const newErrors = {};
    Object.entries(eventData).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
            const error = validateField(key, value);
            if (error) {
                newErrors[key] = error;
            }
        }
    });

    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        console.log('Validation errors:', newErrors);
        return;
    }

    console.log('Submitting event:', eventData);
    onSave(eventData);
};

  
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <span className="close" onClick={onClose}>&times;</span>
                <h2 className="text-2xl font-bold mb-4">{editEvent ? 'Edit Event' : 'Add Event'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 mb-2">Event Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={event.name}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                            required
                        />
                        {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="description" className="block text-gray-700 mb-2">Description</label>
                        <textarea
                            id="description"
                            name="description"
                            value={event.description}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                        />
                        {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="notes" className="block text-gray-700 mb-2">Notes</label>
                        <textarea
                            id="notes"
                            name="notes"
                            value={event.notes}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.notes ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                        />
                        {errors.notes && <p className="text-red-500 text-sm mt-1">{errors.notes}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="practice_sessions" className="block text-gray-700 mb-2">Practice Sessions</label>
                        <input
                            type="number"
                            id="practice_sessions"
                            name="practice_sessions"
                            value={event.practice_sessions}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.practice_sessions ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                            min="0"
                        />
                        {errors.practice_sessions && <p className="text-red-500 text-sm mt-1">{errors.practice_sessions}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="duration" className="block text-gray-700 mb-2">Duration (Seconds)</label>
                        <input
                            type="number"
                            id="duration"
                            name="duration"
                            value={event.duration}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.duration ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                            min="0"
                        />
                        {errors.duration && <p className="text-red-500 text-sm mt-1">{errors.duration}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="average_cart_value" className="block text-gray-700 mb-2">Average Cart Value ($)</label>
                        <input
                            type="number"
                            id="average_cart_value"
                            name="average_cart_value"
                            value={event.average_cart_value}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.average_cart_value ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                            min="0"
                            step="0.01"
                        />
                        {errors.average_cart_value && <p className="text-red-500 text-sm mt-1">{errors.average_cart_value}</p>}
                    </div>
                    {!editEvent && (
                        <div className="mb-4">
                            <label htmlFor="map_url" className="block text-gray-700 mb-2">Map URL</label>
                            <input
                                type="text"
                                id="map_url"
                                name="map_url"
                                value={event.map_url}
                                onChange={handleChange}
                                className={`w-full p-2 border ${errors.map_url ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                            />
                            {errors.map_url && <p className="text-red-500 text-sm mt-1">{errors.map_url}</p>}
                        </div>
                    )}
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-orange-700 transition duration-300"
                        >
                            {editEvent ? 'Update Event' : 'Add Event'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddEvent;