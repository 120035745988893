// components/ParticipantDetails.js
import React from 'react';

function ParticipantDetails({ participant, onClose }) {
        const merchandises = participant.merchandises || [];

        return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md h-5/6 overflow-auto">
                            <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-2xl font-bold">Participant Details</h2>
                                    <button
                                        className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                                        onClick={onClose}
                                    >
                                            Close
                                    </button>
                            </div>
                            <div className="mb-2">
                                    <strong>Name:</strong> {participant.first_name} {participant.last_name}
                            </div>
                            <div className="mb-2">
                                    <strong>Email:</strong> {participant.email}
                            </div>
                            <div className="mb-2">
                                    <strong>Address Line 1:</strong> {participant.address_line_1}
                            </div>
                            <div className="mb-2">
                                    <strong>Address Line 2:</strong> {participant.address_line_2}
                            </div>
                            <div className="mb-2">
                                    <strong>Address Line 3:</strong> {participant.address_line_3}
                            </div>
                            <div className="mb-2">
                                    <strong>City:</strong> {participant.city}
                            </div>
                            <div className="mb-2">
                                    <strong>State Code:</strong> {participant.state_code}
                            </div>
                            <div className="mb-2">
                                    <strong>Postal Code:</strong> {participant.postal_code}
                            </div>
                            <div className="mb-2">
                                    <strong>Country Code:</strong> {participant.country_code}
                            </div>
                            <div className="mb-2">
                                    <strong>Day Phone:</strong> {participant.day_phone}
                            </div>
                            <div className="mb-2">
                                    <strong>Home Phone:</strong> {participant.home_phone}
                            </div>
                            <div className="mb-2">
                                    <strong>Profile Note 1:</strong> {participant.profile_note_1}
                            </div>
                            <div className="mb-2">
                                    <strong>Profile Note 2:</strong> {participant.profile_note_2}
                            </div>
                            <div className="mb-2">
                                    <strong>Profile Note 3:</strong> {participant.profile_note_3}
                            </div>
                            <div className="mb-2">
                                    <strong>Profile Note 4:</strong> {participant.profile_note_4}
                            </div>
                            <div className="mb-4">
                                    <strong>Merchandise:</strong>
                                    {merchandises.length === 0 ? (
                                        <p>No merchandise to show</p>
                                    ) : (
                                        <div className="max-h-40 overflow-y-auto">
                                                <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md text-center">
                                                        <thead>
                                                        <tr className="bg-gray-100">
                                                                <th className="border px-4 py-2">Product ID</th>
                                                                <th className="border px-4 py-2">Item Name</th>
                                                                <th className="border px-4 py-2">Quantity</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {merchandises.map((merchandise) => (
                                                            <tr key={merchandise.id} className="hover:bg-gray-50 transition-colors duration-300">
                                                                    <td className="border px-4 py-2">{merchandise.item_number}</td>
                                                                    <td className="border px-4 py-2">{merchandise.item_name}</td>
                                                                    <td className="border px-4 py-2">{merchandise.ParticipantMerchandise.quantity}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                </table>
                                        </div>
                                    )}
                            </div>
                    </div>
            </div>
        );
}

export default ParticipantDetails;



