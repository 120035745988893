import React, { useState, useEffect } from 'react';

const AddParticipant = ({ onClose, onSave, participantData }) => {
    const [participant, setParticipant] = useState({
        first_name: '',
        last_name: '',
        email: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        city: '',
        state_code: '',
        postal_code: '',
        country_code: '',
        day_phone: '',
        home_phone: '',
        profile_note_1: '',
        profile_note_2: '',
        profile_note_3: '',
        profile_note_4: '',
    });

    useEffect(() => {
        if (participantData) {
            setParticipant(participantData);
        }
    }, [participantData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setParticipant(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const phonePattern = /^[0-9+]*$/;

        if (phonePattern.test(value)) {
            setParticipant(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(participant);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md h-5/6 overflow-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold mb-4">{participantData ? 'Edit Participant' : 'Add Participant'}</h2>
                    <button className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300" onClick={onClose}>
                        Close
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <label className="block mb-2">
                        First Name <span className="text-red-500">*</span>
                        <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={participant.first_name}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                            required
                        />
                    </label>
                    <label className="block mb-2">
                        Last Name <span className="text-red-500">*</span>
                        <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            value={participant.last_name}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                            required
                        />
                    </label>
                    <label className="block mb-2">
                        Email <span className="text-red-500">*</span>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={participant.email}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                            required
                        />
                    </label>
                    <label className="block mb-2">
                        Address Line 1 <span className="text-red-500">*</span>
                        <input
                            type="text"
                            name="address_line_1"
                            placeholder="Address Line 1"
                            value={participant.address_line_1}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                            required
                        />
                    </label>
                    <label className="block mb-2">
                        Address Line 2
                        <input
                            type="text"
                            name="address_line_2"
                            placeholder="Address Line 2"
                            value={participant.address_line_2}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                        />
                    </label>
                    <label className="block mb-2">
                        Address Line 3
                        <input
                            type="text"
                            name="address_line_3"
                            placeholder="Address Line 3"
                            value={participant.address_line_3}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                        />
                    </label>
                    <label className="block mb-2">
                        City <span className="text-red-500">*</span>
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            value={participant.city}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb                            -2"
                            required
                        />
                    </label>
                    <label className="block mb-2">
                        State Code <span className="text-red-500">*</span>
                        <input
                            type="text"
                            name="state_code"
                            placeholder="State Code"
                            value={participant.state_code}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                            required
                        />
                    </label>
                    <label className="block mb-2">
                        Postal Code <span className="text-red-500">*</span>
                        <input
                            type="text"
                            name="postal_code"
                            placeholder="Postal Code"
                            value={participant.postal_code}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                            required
                        />
                    </label>
                    <label className="block mb-2">
                        Country Code <span className="text-red-500">*</span>
                        <input
                            type="text"
                            name="country_code"
                            placeholder="Country Code"
                            value={participant.country_code}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                            required
                        />
                    </label>
                    <label className="block mb-2">
                        Day Phone <span className="text-red-500">*</span>
                        <input
                            type="text"
                            name="day_phone"
                            placeholder="Day Phone"
                            value={participant.day_phone}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                            required
                        />
                    </label>
                    <label className="block mb-2">
                        Home Phone
                        <input
                            type="text"
                            name="home_phone"
                            placeholder="Home Phone"
                            value={participant.home_phone}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                        />
                    </label>
                    <label className="block mb-2">
                        Profile Note 1
                        <textarea
                            name="profile_note_1"
                            placeholder="Profile Note 1"
                            value={participant.profile_note_1}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                        ></textarea>
                    </label>
                    <label className="block mb-2">
                        Profile Note 2
                        <textarea
                            name="profile_note_2"
                            placeholder="Profile Note 2"
                            value={participant.profile_note_2}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                        ></textarea>
                    </label>
                    <label className="block mb-2">
                        Profile Note 3
                        <textarea
                            name="profile_note_3"
                            placeholder="Profile Note 3"
                            value={participant.profile_note_3}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                        ></textarea>
                    </label>
                    <label className="block mb-2">
                        Profile Note 4
                        <textarea
                            name="profile_note_4"
                            placeholder="Profile Note 4"
                            value={participant.profile_note_4}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                        ></textarea>
                    </label>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-orange-700 transition duration-300"
                        >
                            {participantData ? 'Save Changes' : 'Add Participant'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddParticipant;
