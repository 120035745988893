// components/AddEventDate.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';

const AddEventDate = ({ eventId, onClose, onSave, editDate }) => {
    const [date, setDate] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (editDate) {
            setDate(editDate.date.split('T')[0]); // Set initial date value for editing
        } else {
            setDate(new Date().toISOString().split('T')[0]); // Default to today's date
        }
    }, [editDate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editDate) {
                await axiosInstance.put(`/api/events/${eventId}/eventDates/${editDate.id}`, { date });
            } else {
                await axiosInstance.post(`/api/events/${eventId}/eventDates`, { date });
            }
            onSave();
        } catch (err) {
            console.error('Error saving event date:', err);
            setError('Failed to save event date');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4">{editDate ? 'Edit Event Date' : 'Add Event Date'}</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Date:</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-orange-700 transition duration-300"
                        >
                            {editDate ? 'Update Date' : 'Add Date'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddEventDate;
