import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';
import AddMerchandise from './AddMerchandise';
import * as XLSX from 'xlsx';
import placeholderImage from './placeholder.png';
import { TailSpin } from 'react-loader-spinner';
import CountUp from 'react-countup';

function Merchandise() {
    const [merchandise, setMerchandise] = useState([]);
    const [filteredMerchandise, setFilteredMerchandise] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editMerchandise, setEditMerchandise] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [smallPrizeCount, setSmallPrizeCount] = useState(0);
    const [mediumPrizeCount, setMediumPrizeCount] = useState(0);
    const [largePrizeCount, setLargePrizeCount] = useState(0);
    const [limitedPrizeCount, setLimitedPrizeCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedMerchandise, setSelectedMerchandise] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [isDetailsLoading, setIsDetailsLoading] = useState(false);
    const [isBulkUploading, setIsBulkUploading] = useState(false);
    const [bulkUploadStatus, setBulkUploadStatus] = useState('');
    const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = useState(false);
    const [confirmText, setConfirmText] = useState('');

    const fetchMerchandise = useCallback(async () => {
        setIsTableLoading(true);
        try {
            const res = await axiosInstance.get('/api/merchandise');
            const sortMerchandise = (a, b) => {
                const levelOrder = { 'Small': 1, 'Medium': 2, 'Large': 3, 'Premium': 4 };
                if (levelOrder[a.item_level] !== levelOrder[b.item_level]) {
                    return levelOrder[a.item_level] - levelOrder[b.item_level];
                }
                return a.id - b.id;
            };

            res.data.sort(sortMerchandise);

            setMerchandise(res.data);
            setFilteredMerchandise(res.data);
            updateCounters(res.data);
        } catch (err) {
            console.error('Error fetching merchandise:', err);
        }
        finally {
            setIsPageLoading(false);
            setIsTableLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchMerchandise();
    }, [fetchMerchandise]);

    const filterMerchandise = useCallback(() => {
        const filtered = merchandise.filter(item =>
            item.item_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.item_number.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredMerchandise(filtered);
    }, [merchandise, searchQuery]);

    useEffect(() => {
        filterMerchandise();
    }, [searchQuery, merchandise, filterMerchandise]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.relative')) {
                setDropdownVisible(null);
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const updateCounters = (merchandiseList) => {
        setSmallPrizeCount(merchandiseList.filter(item => item.item_level === 'Small').length);
        setMediumPrizeCount(merchandiseList.filter(item => item.item_level === 'Medium').length);
        setLargePrizeCount(merchandiseList.filter(item => item.item_level === 'Large').length);
        setLimitedPrizeCount(merchandiseList.filter(item => item.item_level === 'Premium').length);
    };

    const handleAddMerchandise = async (formData) => {
        try {
            let updatedMerchandise;
            if (editMerchandise) {
                const res = await axiosInstance.put(`/api/merchandise/${editMerchandise.id}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                updatedMerchandise = merchandise.map(m => m.id === editMerchandise.id ? res.data : m);
            } else {
                const res = await axiosInstance.post('/api/merchandise', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                updatedMerchandise = [...merchandise, res.data];
            }

            const sortMerchandise = (a, b) => {
                const levelOrder = { 'Small': 1, 'Medium': 2, 'Large': 3, 'Premium': 4 };
                if (levelOrder[a.item_level] !== levelOrder[b.item_level]) {
                    return levelOrder[a.item_level] - levelOrder[b.item_level];
                }
                return a.id - b.id;
            };

            updatedMerchandise.sort(sortMerchandise);

            setMerchandise(updatedMerchandise);
            setFilteredMerchandise(updatedMerchandise);
            updateCounters(updatedMerchandise);
            setShowModal(false);
            setEditMerchandise(null);
        } catch (err) {
            console.error('Error adding/updating merchandise:', err);
        }
    };

    const handleDeleteMerchandise = async (itemId) => {
        try {
            await axiosInstance.delete(`/api/merchandise/${itemId}`);
            const updatedMerchandise = merchandise.filter(item => item.id !== itemId);
            setMerchandise(updatedMerchandise);
            setFilteredMerchandise(updatedMerchandise);
            updateCounters(updatedMerchandise);
        } catch (err) {
            console.error('Error deleting merchandise:', err);
        }
    };

    const handleDeleteSelected = async () => {
        try {
            await Promise.all(
                selectedItems.map(itemId => axiosInstance.delete(`/api/merchandise/${itemId}`))
            );
            const updatedMerchandise = merchandise.filter(item => !selectedItems.includes(item.id));
            setMerchandise(updatedMerchandise);
            setFilteredMerchandise(updatedMerchandise);
            setSelectedItems([]);
            updateCounters(updatedMerchandise);
        } catch (err) {
            console.error('Error deleting selected merchandise:', err);
        }
    };

    const handleDeleteAll = async () => {
        if (confirmText.toLowerCase() === 'confirm') {
            setIsLoading(true);
            try {
                await axiosInstance.delete(`/api/merchandise`);
                setMerchandise([]);
                setFilteredMerchandise([]);
                updateCounters([]);
                setShowDeleteAllConfirmation(false);
                setConfirmText('');
                setStatusMessage('All merchandise has been deleted successfully.');
            } catch (err) {
                console.error('Error deleting all merchandise:', err);
                setStatusMessage(err.response?.data?.message || 'An error occurred while trying to delete all merchandise.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const toggleDropdown = (itemId) => {
        setDropdownVisible((prevState) => (
            prevState === itemId ? null : itemId
        ));
    };

    const handleEditClick = (item) => {
        setEditMerchandise(item);
        setShowModal(true);
        setDropdownVisible(null);
    };

    const handleAddClick = () => {
        setEditMerchandise(null);
        setShowModal(true);
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        setIsLoading(true);
        setStatusMessage('');
        reader.onload = async (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            const formattedData = rows.slice(1).map(row => ({
                item_number: row[0],
                item_name: row[1],
                sell_price: typeof row[2] === 'string' ? parseFloat(row[2].replace(/[^0-9.-]+/g, "")) : row[2],
                item_level: row[3]
            }));
            try {
                await axiosInstance.post('/api/merchandise/import', { data: formattedData });
                setStatusMessage('Merchandise imported successfully');
                fetchMerchandise();
            } catch (err) {
                console.error('Error importing merchandise:', err);
                setStatusMessage('Error importing merchandise');
            } finally {
                setIsLoading(false);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleSelectItem = (itemId) => {
        setSelectedItems(prev =>
            prev.includes(itemId) ? prev.filter(id => id !== itemId) : [...prev, itemId]
        );
    };

    const handleSelectAll = () => {
        if (selectedItems.length === merchandise.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(merchandise.map(item => item.id));
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleViewDetails = async (item) => {
        setIsDetailsLoading(true);
        try {
            const res = await axiosInstance.get(`/api/merchandise/${item.id}`);
            setSelectedMerchandise({ ...res.data, events: res.data.events || [] });
            setShowDetailsModal(true);
        } catch (err) {
            console.error('Error fetching merchandise details:', err);
        } finally {
            setIsDetailsLoading(false);
        }
    };

    const handleBulkImageUpload = async (e) => {
        const files = e.target.files;
        if (files.length === 0) return;

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('images', files[i]);
        }

        setIsBulkUploading(true);
        setBulkUploadStatus('Uploading images...');

        try {
            const response = await axiosInstance.post('/api/upload/bulk-merchandise-images', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setBulkUploadStatus(`Upload complete. Uploaded: ${response.data.uploaded.length}, Skipped: ${response.data.skipped.length}, Failed: ${response.data.failed.length}`);
            await fetchMerchandise(); // Refresh the merchandise list
        } catch (error) {
            console.error('Error in bulk upload:', error);
            setBulkUploadStatus('Error uploading images. Please try again.');
        } finally {
            setIsBulkUploading(false);
        }
    };

    return (
        <div className="p-4">
            {isPageLoading ? (
                <div className="flex items-center justify-center h-full">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
            ) : (
                <>
                    <h2 className="text-3xl font-bold mb-4">Merchandise</h2>
                    <div className="flex items-center mb-4">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="border border-gray-300 rounded-lg p-2 mr-4"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <button
                            className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                            onClick={handleAddClick}
                        >
                            Add Merchandise
                        </button>
                        <input type="file" id="bulkUpload" style={{display: 'none'}} onChange={handleFileUpload}/>
                        <button
                            className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ml-4"
                            onClick={() => document.getElementById('bulkUpload').click()}
                        >
                            Bulk Upload Merchandises
                        </button>
                        <input
                            type="file"
                            id="bulkImageUpload"
                            multiple
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleBulkImageUpload}
                        />
                        <button
                            className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ml-4"
                            onClick={() => document.getElementById('bulkImageUpload').click()}
                            disabled={isBulkUploading}
                        >
                            {isBulkUploading ? 'Uploading...' : 'Bulk Image Upload'}
                        </button>
                    </div>
                    {isBulkUploading && (
                        <div className="flex justify-center items-center">
                            <TailSpin height="50" width="50" color="#4fa94d" />
                            <span className="ml-2">Uploading images...</span>
                        </div>
                    )}
                    {bulkUploadStatus && <div className="text-blue-600 mb-4">{bulkUploadStatus}</div>}
                    {isLoading &&
                        <div className="flex justify-center"><TailSpin height="50" width="50" color="#4fa94d"/></div>}
                    {statusMessage && <div className="text-green-600 mb-4">{statusMessage}</div>}
                    {bulkUploadStatus && <div className="text-blue-600 mb-4">{bulkUploadStatus}</div>}
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold">Small</h3>
                            <CountUp end={smallPrizeCount} duration={2} className="text-2xl"/>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold">Medium</h3>
                            <CountUp end={mediumPrizeCount} duration={2} className="text-2xl" />
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold">Large</h3>
                            <CountUp end={largePrizeCount} duration={2} className="text-2xl" />
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold">Premium</h3>
                            <CountUp end={limitedPrizeCount} duration={2} className="text-2xl" />
                        </div>
                    </div>
                    <div className="flex mb-4">
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 mr-4"
                            onClick={handleDeleteSelected}
                        >
                            Delete Selected
                        </button>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                            onClick={() => setShowDeleteAllConfirmation(true)}
                        >
                            Delete All
                        </button>
                    </div>
                    <div className="overflow-x-auto">
                        {isTableLoading ? (
                            <div className="flex items-center justify-center h-full">
                                <div
                                    className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                            </div>
                        ) : (
                            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                                <thead>
                                <tr className="bg-gray-100">
                                    <th className="border px-4 py-2">
                                        <input
                                            type="checkbox"
                                            checked={selectedItems.length === merchandise.length}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th className="border px-4 py-2">ID</th>
                                    <th className="border px-4 py-2">Name</th>
                                    <th className="border px-4 py-2">Sell Price</th>
                                    <th className="border px-4 py-2">Level</th>
                                    <th className="border px-4 py-2">Image</th>
                                    <th className="border px-4 py-2">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredMerchandise.map(item => (
                                    <tr key={item.id} className="hover:bg-gray-50">
                                        <td className="border px-4 py-2">
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(item.id)}
                                                onChange={() => handleSelectItem(item.id)}
                                            />
                                        </td>
                                        <td className="border px-4 py-2">{item.item_number}</td>
                                        <td className="border px-4 py-2">{item.item_name}</td>
                                        <td className="border px-4 py-2">${item.sell_price}</td>
                                        <td className="border px-4 py-2">{item.item_level}</td>
                                        <td className="border px-4 py-2">{item.image_url &&
                                            <img src={item.image_url} alt="Merchandise"
                                                 className="w-12 h-12 object-cover"/>}</td>
                                        <td className="border px-4 py-2">
                                            <div className="relative">
                                                <button className="text-primary hover:text-orange-700 transition duration-300"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleDropdown(item.id);
                                                        }}>⚙️
                                                </button>
                                                {dropdownVisible === item.id && (
                                                    <div
                                                        className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50"
                                                    >
                                                        <button
                                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditClick(item);
                                                                setDropdownVisible(null);
                                                            }}
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDeleteMerchandise(item.id);
                                                                setDropdownVisible(null);
                                                            }}
                                                        >
                                                            Delete
                                                        </button>
                                                        <button
                                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleViewDetails(item);
                                                                setDropdownVisible(null);
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    </div>
                                                )}

                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    {showModal && (
                        <AddMerchandise
                            onClose={() => setShowModal(false)}
                            onSave={handleAddMerchandise}
                            editMerchandise={editMerchandise}
                        />
                    )}
                    {showDetailsModal && selectedMerchandise && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            {isDetailsLoading ? (
                                <div className="flex items-center justify-center h-full">
                                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                                </div>
                            ) : (
                                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                                    <h2 className="text-2xl font-bold mb-4">Merchandise Details</h2>
                                    <img
                                        src={selectedMerchandise.image_url ? selectedMerchandise.image_url : placeholderImage} alt="Merchandise"
                                        className="w-24 h-24 object-cover mb-4"
                                    />
                                    <p><strong>ID:</strong> {selectedMerchandise.item_number}</p>
                                    <p><strong>Name:</strong> {selectedMerchandise.item_name}</p>
                                    <p><strong>Sell Price:</strong> ${selectedMerchandise.sell_price}</p>
                                    <p><strong>Level:</strong> {selectedMerchandise.item_level}</p>
                                    <p><strong>Linked Events:</strong> {selectedMerchandise.events.length > 0 ? selectedMerchandise.events.map(event => event.name).join(', ') : 'Not linked to any event'}</p>
                                    <button
                                        className="mt-4 bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                                        onClick={() => setShowDetailsModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
            {showDeleteAllConfirmation && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                        <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                        <p className="mb-4">Type "confirm" to delete all merchandise.</p>
                        <input
                            type="text"
                            value={confirmText}
                            onChange={(e) => setConfirmText(e.target.value)}
                            className="p-2 border border-gray-300 rounded-lg w-full mb-4"
                        />
                        <div className="flex justify-between">
                            <button
                                className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-800 transition duration-300"
                                onClick={handleDeleteAll}
                            >
                                Delete All
                            </button>
                            <button
                                className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                                onClick={() => {
                                    setShowDeleteAllConfirmation(false);
                                    setConfirmText('');
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Merchandise;
