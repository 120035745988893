import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import AddEventDate from './AddEventDate';
import ParticipantsList from './ParticipantsList';
import MerchandiseList from './MerchandiseList';
import InteractiveMap from './InteractiveMap';
import EventLogoUpload from './EventLogoUpload';
import MapImageUpload from './MapImageUpload';

function EventDetails() {
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showParticipantsList, setShowParticipantsList] = useState(false);
    const [showAddEventDateModal, setShowAddEventDateModal] = useState(false);
    const [showMerchandiseList, setShowMerchandiseList] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState({});
    const [editDate, setEditDate] = useState(null);
    const [showMapModal, setShowMapModal] = useState(false);
    const [showEventLogoUpload, setShowEventLogoUpload] = useState(false);
    const [showMapImageUpload, setShowMapImageUpload] = useState(false);

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const res = await axiosInstance.get(`/api/events/${eventId}`);
                setEvent(res.data);
                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching event details:', err);
                setIsLoading(false);
            }
        };
        fetchEventDetails();
    }, [eventId]);

    const toggleDropdown = (dateId) => {
        setDropdownVisible((prevState) => ({
            ...prevState,
            [dateId]: !prevState[dateId],
        }));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.relative')) {
                setDropdownVisible({});
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleEditDate = (date) => {
        setEditDate(date);
        setShowAddEventDateModal(true);
        setDropdownVisible({});
    };

    const handleDeleteDate = async (dateId) => {
        try {
            await axiosInstance.delete(`/api/events/${eventId}/eventDates/${dateId}`);
            setEvent((prevEvent) => ({
                ...prevEvent,
                eventDates: prevEvent.eventDates.filter((date) => date.id !== dateId)
            }));
            setDropdownVisible({});
        } catch (err) {
            console.error('Error deleting event date:', err);
        }
    };

    const toggleMapModal = () => {
        setShowMapModal(!showMapModal);
    };

    const toggleEventLogoUpload = () => {
        setShowEventLogoUpload(!showEventLogoUpload);
    };

    const toggleMapImageUpload = () => {
        setShowMapImageUpload(!showMapImageUpload);
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
        );
    }

    if (!event) {
        return <div className="text-center text-gray-500">Event not found</div>;
    }

    return (
        <div className="flex-grow p-4 transition-all duration-300">
            {event.logo_url && (
                <div className="flex justify-center mb-4">
                    <img src={event.logo_url} alt="Event Logo" className="w-32 h-32 object-cover" />
                </div>
            )}
            <h2 className="text-3xl font-bold mb-4">{event.name}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="bg-white p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
                    <h3 className="text-xl font-semibold">Completed Events</h3>
                    <p className="text-2xl">{event.eventDates.filter(date => date.status === 'Completed').length}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
                    <h3 className="text-xl font-semibold">Total Events</h3>
                    <p className="text-2xl">{event.eventDates.length}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
                    <h3 className="text-xl font-semibold">Active Events</h3>
                    <p className="text-2xl">{event.eventDates.filter(date => date.status === 'Active').length}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
                    <h3 className="text-xl font-semibold">Number of Participants</h3>
                    <p className="text-2xl">{event.participants.length}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
                    <h3 className="text-xl font-semibold">Activity Duration (Seconds)</h3>
                    <p className="text-2xl">{event.duration}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
                    <h3 className="text-xl font-semibold">Average Cart Value ($)</h3>
                    <p className="text-2xl">{event.average_cart_value}</p>
                </div>
            </div>
            <div className="flex space-x-4 mb-4">
                <button
                    className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={() => {
                        setEditDate(null);
                        setShowAddEventDateModal(true);
                    }}
                >
                    Add Event Date
                </button>
                <button
                    className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={() => setShowParticipantsList(true)}
                >
                    View Participants
                </button>
                <button
                    className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={() => setShowMerchandiseList(true)}
                >
                    Manage Merchandises
                </button>
                <button
                    className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={toggleEventLogoUpload}
                >
                    Upload Event Logo
                </button>
                <button
                    className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={toggleMapImageUpload}
                >
                    Upload Map Image
                </button>
            </div>
            <div className="overflow-auto">
                <table
                    className="w-full bg-white border border-gray-200 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg text-center">
                    <thead>
                    <tr className="bg-gray-100">
                        <th className="border px-4 py-2">Date</th>
                        <th className="border px-4 py-2">Status</th>
                        <th className="border px-4 py-2">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {event.eventDates.map(date => (
                        <tr key={date.id} className="hover:bg-gray-50 transition-colors duration-300">
                            <td className="border px-4 py-2">{new Date(date.date).toLocaleDateString()}</td>
                            <td className="border px-4 py-2">{date.status}</td>
                            <td className="border px-4 py-2">
                                <div className="relative">
                                    <button
                                        className="text-primary hover:text-orange-700 transition duration-300"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleDropdown(date.id);
                                        }}
                                    >
                                        ⚙️
                                    </button>
                                    {dropdownVisible[date.id] && (
                                        <div
                                            className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg bg-opacity-100 transition-opacity duration-300"
                                            style={{zIndex: 1000}}
                                        >
                                            <button
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition duration-300"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditDate(date);
                                                }}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition duration-300"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteDate(date.id);
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {showParticipantsList && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl h-5/6 overflow-auto">
                        <ParticipantsList participants={event.participants} eventId={eventId}
                                          onClose={() => setShowParticipantsList(false)}/>
                    </div>
                </div>
            )}
            {showMerchandiseList && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl h-5/6 overflow-auto">
                        <MerchandiseList eventId={eventId} onClose={() => setShowMerchandiseList(false)}/>
                    </div>
                </div>
            )}
            {showAddEventDateModal && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md h-5/6 overflow-auto">
                        <AddEventDate eventId={eventId} editDate={editDate}
                                      onClose={() => setShowAddEventDateModal(false)} onSave={() => {
                            // Fetch the updated event details after saving the event date
                            const fetchEventDetails = async () => {
                                try {
                                    const res = await axiosInstance.get(`/api/events/${eventId}`);
                                    setEvent(res.data);
                                } catch (err) {
                                    console.error('Error fetching event details:', err);
                                }
                            };
                            fetchEventDetails();
                            setShowAddEventDateModal(false);
                        }}/>
                    </div>
                </div>
            )}
            <div>
                <button
                    className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={toggleMapModal}
                >
                    Map
                </button>
                {showMapModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl h-5/6 overflow-auto">
                            <button
                                className="text-gray-500 hover:text-gray-700 transition duration-300"
                                onClick={toggleMapModal}
                            >
                                Close
                            </button>
                            <InteractiveMap eventID={event.id} mapImage={event.map_url} />
                        </div>
                    </div>
                )}
            </div>
            {showEventLogoUpload && (
                <EventLogoUpload eventID={eventId} onClose={toggleEventLogoUpload} onUpload={() => {
                    const fetchEventDetails = async () => {
                        try {
                            const res = await axiosInstance.get(`/api/events/${eventId}`);
                            setEvent(res.data);
                        } catch (err) {
                            console.error('Error fetching event details:', err);
                        }
                    };
                    fetchEventDetails();
                    toggleEventLogoUpload();
                }}/>
            )}
            {showMapImageUpload && (
                <MapImageUpload eventID={eventId} onClose={toggleMapImageUpload} onUpload={() => {
                    const fetchEventDetails = async () => {
                        try {
                            const res = await axiosInstance.get(`/api/events/${eventId}`);
                            setEvent(res.data);
                        } catch (err) {
                            console.error('Error fetching event details:', err);
                        }
                    };
                    fetchEventDetails();
                    toggleMapImageUpload();
                }}/>
            )}
        </div>
    );
}

export default EventDetails;
