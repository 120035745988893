// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Merchandise from './components/Merchandise';
import Events from './components/Events';
import EventDetails from './components/EventDetails';
import Sidebar from './components/Sidebar';
import PrivateRoute from './components/PrivateRoute';
import './tailwind.css';

function App() {
    const location = useLocation();
    const isLoginScreen = location.pathname === '/login';

    return (
        <div className="flex h-screen flex-col">
            <div className="flex flex-grow">
                {!isLoginScreen && <Sidebar />}
                <div className={`flex flex-col flex-grow ${isLoginScreen ? '' : 'pl-64'}`}>
                    <div className="flex-grow p-4 overflow-auto">
                        <Routes>
                            <Route path="/" element={<Navigate to="/login" />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                            <Route path="/merchandise" element={<PrivateRoute><Merchandise /></PrivateRoute>} />
                            <Route path="/events" element={<PrivateRoute><Events /></PrivateRoute>} />
                            <Route path="/event-details/:eventId" element={<PrivateRoute><EventDetails /></PrivateRoute>} />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;
