// components/Dashboard.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';

function Dashboard() {
    const [totalEvents, setTotalEvents] = useState(0);
    const [merchandiseTotal, setMerchandiseTotal] = useState(0);
    const [activeEvents, setActiveEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const eventsResponse = await axiosInstance.get('/api/events');
                const merchandiseResponse = await axiosInstance.get('/api/merchandise');

                const allEvents = eventsResponse.data;

                let activeEventsList = [];
                let upcomingEventsList = [];

                allEvents.forEach(event => {
                    event.eventDates.forEach(date => {
                        if (date.status === 'Active') {
                            activeEventsList.push({
                                ...date,
                                eventName: event.name
                            });
                        } else if (date.status === 'Upcoming' || new Date(date.date) > new Date()) {
                            upcomingEventsList.push({
                                ...date,
                                eventName: event.name
                            });
                        }
                    });
                });

                setTotalEvents(allEvents.length);
                setMerchandiseTotal(merchandiseResponse.data.length);
                setActiveEvents(activeEventsList);
                setUpcomingEvents(upcomingEventsList);
                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleEventClick = (eventId) => {
        navigate(`/event-details/${eventId}`);
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
        );
    }

    return (
        <div className="flex-grow p-4">
            <h2 className="text-3xl font-bold mb-4">Dashboard</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                <div className="bg-white p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
                    <h3 className="text-xl font-semibold">Total Events</h3>
                    <CountUp end={totalEvents} duration={2} className="text-2xl"/>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
                    <h3 className="text-xl font-semibold">Active Events</h3>
                    <CountUp end={activeEvents.length} duration={2} className="text-2xl"/>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-105">
                    <h3 className="text-xl font-semibold">Merchandise Total</h3>
                    <CountUp end={merchandiseTotal} duration={2} className="text-2xl"/>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-white p-4 rounded-lg shadow-md max-h-80 overflow-y-auto">
                    <h3 className="text-xl font-semibold mb-4">Active Events</h3>
                    {activeEvents.length === 0 ? (
                        <p className="text-gray-500">No active events</p>
                    ) : (
                        <ul className="space-y-2">
                            {activeEvents.map(event => (
                                <li
                                    key={event.id}
                                    className="p-2 border border-gray-200 rounded-lg transition-colors duration-300 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleEventClick(event.event_id)}
                                >
                                    <h4 className="text-lg font-bold">{event.eventName}</h4>
                                    <p>{new Date(event.date).toLocaleDateString()}</p>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md max-h-80 overflow-y-auto">
                    <h3 className="text-xl font-semibold mb-4">Upcoming Events</h3>
                    {upcomingEvents.length === 0 ? (
                        <p className="text-gray-500">No upcoming events</p>
                    ) : (
                        <ul className="space-y-2">
                            {upcomingEvents.map(event => (
                                <li
                                    key={event.id}
                                    className="p-2 border border-gray-200 rounded-lg transition-colors duration-300 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleEventClick(event.event_id)}
                                >
                                    <h4 className="text-lg font-bold">{event.eventName}</h4>
                                    <p>{new Date(event.date).toLocaleDateString()}</p>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
