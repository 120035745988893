// components/EventLogoUpload.js
import React, { useState } from 'react';
import axiosInstance from '../utils/axiosInstance';

function EventLogoUpload({ eventID, onClose, onUpload }) {
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('logoImage', file);
        formData.append('eventID', eventID);

        try {
            const res = await axiosInstance.post(`/api/upload/event-logo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            onUpload(res.data.filePath);
            onClose();
        } catch (err) {
            console.error('Error uploading event logo:', err);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <div className="flex justify-end mb-4">
                    <button
                        className="text-gray-500 hover:text-gray-700 transition duration-300"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
                <h2 className="text-2xl font-bold mb-4 text-center">Upload Event Logo</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="block w-full text-gray-700 mb-4"
                    />
                    {imagePreview && <img src={imagePreview} alt="Logo Preview" className="w-full h-auto mb-4" />}
                    <div className="flex justify-end space-x-2">
                        <button
                            type="submit"
                            className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-orange-700 transition duration-300"
                        >
                            Upload
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EventLogoUpload;
