import React, { useState, useEffect, useRef, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { exportAsImage, exportAsPDF } from '../utils/ImageExport';
import './InteractiveMap.css';

function InteractiveMap({ eventID, mapImage, onClose }) {
    const [loading, setLoading] = useState(false);
    const [merchandiseLoading, setMerchandiseLoading] = useState(true);
    const [merchandise, setMerchandise] = useState([]);
    const [localMapImage, setLocalMapImage] = useState(null);
    const mapContainerRef = useRef(null);
    const [originalMapUrl, setOriginalMapUrl] = useState(null);

    useEffect(() => {
        fetchMerchandise();
    }, []);

    const fetchMerchandise = async () => {
        setMerchandiseLoading(true);
        try {
            const response = await axiosInstance.get(`/api/eventMerchandise/${eventID}/merchandise`);
            const fetchedMerchandise = response.data.map(item => ({
                ...item,
                placementPosition: item.placementPosition || null
            }));

            // Sort the merchandise immediately after fetching
            const sortedMerchandise = fetchedMerchandise.sort((a, b) => {
                if (a.placementPosition === null) return 1;
                if (b.placementPosition === null) return -1;
                return a.placementPosition - b.placementPosition;
            });

            setMerchandise(sortedMerchandise);

            const localImage = await downloadImage(mapImage);
            if (localImage) {
                setLocalMapImage(localImage);
            }
        } catch (error) {
            console.error('Error fetching merchandise:', error);
        } finally {
            setMerchandiseLoading(false);
        }
    };

    const downloadImage = async (url) => {
        try {
            const response = await fetch(url, { mode: 'cors' });
            if (response.ok) {
                const blob = await response.blob();
                setOriginalMapUrl(url);
                return URL.createObjectURL(blob);
            }

            const proxyResponse = await axiosInstance.get(`/api/proxy-image?url=${encodeURIComponent(url)}`, {
                responseType: 'blob'
            });
            setOriginalMapUrl(url);
            return URL.createObjectURL(proxyResponse.data);
        } catch (error) {
            console.error('Error downloading image:', error);
            return '/path/to/placeholder-image.jpg';
        }
    };

    const handleExport = async (type) => {
        setLoading(true);
        const exportData = {
            localMapImage: originalMapUrl || localMapImage,
            merchandise,
        };
        if (type === 'image') {
            await exportAsImage(exportData);
        } else {
            await exportAsPDF(exportData);
        }
        setLoading(false);
    };

    const handleNumberChange = useCallback(async (e, merchandiseId) => {
        const newPosition = e.target.value === '' ? null : parseInt(e.target.value, 10);

        setMerchandise(prevMerchandise => {
            const updatedMerchandise = prevMerchandise.map(item => {
                if (item.id === merchandiseId) {
                    return { ...item, placementPosition: newPosition };
                }
                return item;
            });

            // Sort the updated merchandise array
            return updatedMerchandise.sort((a, b) => {
                if (a.placementPosition === null) return 1;
                if (b.placementPosition === null) return -1;
                return a.placementPosition - b.placementPosition;
            });
        });

        try {
            await axiosInstance.post(`/api/eventMerchandise/${eventID}/merchandise`, {
                merchandiseId,
                placementPosition: newPosition,
            });
        } catch (error) {
            console.error('Error updating merchandise position:', error);
        }
    }, [eventID]);

    const getItemSize = (level) => {
        switch (level) {
            case 'Small': return 'small';
            case 'Medium': return 'medium';
            case 'Large': return 'large';
            case 'Premium': return 'premium';
            default: return 'small';
        }
    };

    const getColorForItemLevel = (level) => {
        switch (level) {
            case 'Small': return 'bg-green-100 border-green-300';
            case 'Medium': return 'bg-yellow-100 border-yellow-300';
            case 'Large': return 'bg-blue-100 border-blue-300';
            case 'Premium': return 'bg-purple-100 border-purple-300';
            default: return 'bg-gray-100 border-gray-300';
        }
    };

    return (
        <div className="interactive-map-container">
            <div className="map-section">
                <div className="button-container">
                    <button className="export-button" onClick={() => handleExport('image')}>
                        Export as Image
                    </button>
                    <button className="export-button" onClick={() => handleExport('pdf')}>
                        Export as PDF
                    </button>
                </div>
                <div className="prize-legend">
                    <span className="legend-item"><span className="circle bg-green-300"></span> Small Prize</span>
                    <span className="legend-item"><span className="circle bg-yellow-300"></span> Medium Prize</span>
                    <span className="legend-item"><span className="circle bg-blue-300"></span> Large Prize</span>
                    <span className="legend-item"><span className="circle bg-purple-300"></span> Premium Prize</span>
                </div>
                <div className="map-content">
                    {localMapImage ? (
                        <img src={localMapImage} alt="Map" className="map-image" />
                    ) : (
                        <p>Loading map...</p>
                    )}
                </div>
            </div>
            <div className="merchandise-section">
                {merchandiseLoading ? (
                    <div className="loading-container"><div className="loader"></div></div>
                ) : merchandise.length === 0 ? (
                    <p>No merchandise available for this event.</p>
                ) : (
                    <div className="bento-grid">
                        {merchandise.map((item) => (
                            <div
                                key={item.id}
                                className={`bento-item ${getColorForItemLevel(item.item_level)}`}
                            >
                                <h3 className="item-name">{item.item_name}</h3>
                                <select
                                    value={item.placementPosition || ''}
                                    onChange={(e) => handleNumberChange(e, item.id)}
                                    className="position-select"
                                >
                                    <option value="">Not assigned</option>
                                    {Array.from({ length: merchandise.length }, (_, i) => i + 1).map(number => (
                                        <option key={number} value={number}>{number}</option>
                                    ))}
                                </select>
                                <p className="current-placement">
                                    {item.placementPosition ? `Position: ${item.placementPosition}` : 'Not assigned'}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default InteractiveMap;