import React, { useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import * as XLSX from 'xlsx';

function UploadXlsx({ eventId, onClose }) {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [duplicates, setDuplicates] = useState([]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        if (!file) return;

        setIsLoading(true);
        setStatusMessage('');
        setErrorMessage('');
        setDuplicates([]);

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post(`/api/participants/${eventId}/participants/import`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setStatusMessage(response.data.message);
            setDuplicates(response.data.duplicates);
        } catch (err) {
            console.error('Error importing participants:', err.response?.data || err.message);
            setErrorMessage(err.response?.data?.message || 'Error importing participants');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadSample = () => {
        const ws_data = [
            ['final_run_date', 'sequence', 'full_name', 'address_line_1', 'address_line_2', 'address_line_3', 'city', 'state', 'postal', 'country', 'day_phone', 'home_phone', 'email', 'profile_note_1', 'profile_note_2', 'profile_note_3', 'profile_note_4', 'delimiter', 'product_1', 'quantity_1'],
            ['2023-01-01', '1', 'John Doe', '123 Main St', '', '', 'City', 'State', '12345', 'Country', '123-456-7890', '123-456-7890', 'john.doe@example.com', 'Note 1', 'Note 2', 'Note 3', 'Note 4', '|', 'Merch1', '1']
        ];

        const ws = XLSX.utils.aoa_to_sheet(ws_data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sample");
        XLSX.writeFile(wb, "sample.xlsx");
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4">Upload XLSX</h2>
                <form onSubmit={handleFileUpload}>
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                        className="block w-full text-gray-700 mb-4"
                    />
                    <div className="flex justify-between mb-4">
                        <button
                            type="button"
                            className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-orange-700 transition duration-300"
                            onClick={handleDownloadSample}
                        >
                            Download Sample XLSX
                        </button>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-orange-700 transition duration-300"
                        >
                            Upload
                        </button>
                    </div>
                </form>
                {isLoading && (
                    <div className="flex justify-center mt-4">
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                    </div>
                )}
                {statusMessage && <div className="mt-4 text-green-600">{statusMessage}</div>}
                {errorMessage && <div className="mt-4 text-red-600">{errorMessage}</div>}
                {duplicates.length > 0 && (
                    <div className="mt-4">
                        <h3 className="text-red-600 font-bold">Duplicates Found:</h3>
                        <ul className="mt-2 text-red-600">
                            {duplicates.map((duplicate, index) => (
                                <li key={index}>
                                    {duplicate.duplicateData.email} already exists with event ID: {duplicate.existingParticipant.event_id}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UploadXlsx;
