import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import CryptoJS from 'crypto-js';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const secretKey = process.env.REACT_APP_SHARED_ENCRYPTION_SECRET;

const encryptToken = (token) => {
    if (!secretKey) {
        console.error('SHARED_ENCRYPTION_SECRET is not defined');
        return token;
    }
    return CryptoJS.AES.encrypt(token, secretKey).toString();
};

const decryptToken = (encryptedToken) => {
    if (!secretKey) {
        console.error('SHARED_ENCRYPTION_SECRET is not defined');
        return encryptedToken;
    }
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error('Error decrypting token:', error);
        return null;
    }
};

export const setAuthToken = (token) => {
    if (token) {
        const encryptedToken = encryptToken(token);
        localStorage.setItem('token', encryptedToken);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        localStorage.removeItem('token');
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

axiosInstance.interceptors.request.use(
    (config) => {
        const encryptedToken = localStorage.getItem('token');
        if (encryptedToken) {
            try {
                const token = decryptToken(encryptedToken);
                if (token) {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Date.now() / 1000;

                    if (decodedToken.exp < currentTime) {
                        setAuthToken(null);
                        window.location.href = '/login';
                        return Promise.reject(new Error('Token has expired'));
                    }

                    config.headers.Authorization = `Bearer ${token}`;
                } else {
                    setAuthToken(null);
                    window.location.href = '/login';
                    return Promise.reject(new Error('Invalid token'));
                }
            } catch (error) {
                console.error('Error processing token:', error);
                setAuthToken(null);
                window.location.href = '/login';
                return Promise.reject(new Error('Invalid token'));
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { encryptToken, decryptToken };
export default axiosInstance;