// components/Sidebar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Sidebar() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <div className="w-64 bg-primary text-white flex flex-col h-full fixed">
            <h1 className="text-2xl text-center font-bold p-4">Windfall VR</h1>
            <nav className="flex flex-col flex-grow items-center">
                <Link to="/dashboard" className="p-4 w-full text-center hover:bg-orange-700 transition duration-300">Dashboard</Link>
                <Link to="/merchandise" className="p-4 w-full text-center hover:bg-orange-700 transition duration-300">Merchandise</Link>
                <Link to="/events" className="p-4 w-full text-center hover:bg-orange-700 transition duration-300">Events</Link>
            </nav>
            <button
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 m-4 rounded-lg transition duration-300"
                onClick={handleLogout}
            >
                Logout
            </button>
            <footer className="text-white text-xs text-center p-2">
                <p>© 2024 BIW Windfall VR.</p>
            </footer>
        </div>
    );
}

export default Sidebar;
