import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import AddMerchandise from './AddMerchandise';

function MerchandiseList({ eventId, onClose }) {
    const [merchandise, setMerchandise] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentMerchandise] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchMerchandise();
    }, []);

    const fetchMerchandise = async () => {
        try {
            const response = await axiosInstance.get('/api/merchandise');
            const eventMerchandiseResponse = await axiosInstance.get(`/api/eventMerchandise/${eventId}/merchandise`);
            const eventMerchandise = eventMerchandiseResponse.data.reduce((acc, item) => {
                acc[item.id] = item.placementPosition;
                return acc;
            }, {});

            const updatedMerchandise = response.data.map(item => ({
                ...item,
                placementPosition: eventMerchandise[item.id] || 'Not assigned',
                isAdded: eventMerchandise.hasOwnProperty(item.id),
            }));

            // Apply the sorting
            const sortedMerchandise = sortMerchandise(updatedMerchandise);

            setMerchandise(sortedMerchandise);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching merchandise:', error);
            setIsLoading(false);
        }
    };

    const sortMerchandise = (items) => {
        const levelOrder = { 'Small': 1, 'Medium': 2, 'Large': 3, 'Premium': 4 };

        return items.sort((a, b) => {
            // First, sort by whether the item is assigned to the event
            if (a.placementPosition !== 'Not assigned' && b.placementPosition === 'Not assigned') return -1;
            if (a.placementPosition === 'Not assigned' && b.placementPosition !== 'Not assigned') return 1;

            // If both are assigned or both are not assigned, sort by level
            if (levelOrder[a.item_level] !== levelOrder[b.item_level]) {
                return levelOrder[a.item_level] - levelOrder[b.item_level];
            }

            // If levels are the same, sort by item number
            return a.item_number.localeCompare(b.item_number);
        });
    };

    const handleAddMerchandiseToEvent = async (itemId) => {
        try {
            const nextAvailablePosition = findNextAvailablePosition();
            if (nextAvailablePosition === null) {
                console.error('No available position found');
                return;
            }
            await axiosInstance.post(`/api/eventMerchandise/${eventId}/merchandise`, {
                merchandiseId: itemId,
                placementPosition: nextAvailablePosition,
            });
            await fetchMerchandise(); // Refresh the merchandise list
        } catch (error) {
            console.error('Error adding merchandise to event:', error);
            // show an error message to the user here
        }
    };

    const handleRemoveMerchandiseFromEvent = async (itemId) => {
        try {
            await axiosInstance.delete(`/api/eventMerchandise/${eventId}/merchandise/${itemId}`);
            await fetchMerchandise(); // Refresh the merchandise list
        } catch (error) {
            console.error('Error removing merchandise from event:', error);
        }
    };

    const handleSelectAll = async () => {
        setIsProcessing(true);
        try {
            const itemsToAdd = merchandise.filter(item => !item.isAdded).map(item => item.id);

            if (itemsToAdd.length === 0) {
                console.error('No merchandise items to add');
                setIsProcessing(false);
                return;
            }

            // Generate a sequence of unique placement positions
            const assignedPositions = merchandise
                .filter(item => item.isAdded)
                .map(item => parseInt(item.placementPosition))
                .filter(pos => !isNaN(pos));

            const startPosition = Math.max(0, ...assignedPositions) + 1;

            const uniquePositions = Array.from({ length: itemsToAdd.length }, (_, i) => startPosition + i);

            await axiosInstance.post(`/api/eventMerchandise/${eventId}/merchandise/bulk`, {
                merchandiseIds: itemsToAdd,
                positions: uniquePositions
            });

            await fetchMerchandise();
        } catch (error) {
            console.error('Error adding all merchandise to event:', error);
        } finally {
            setIsProcessing(false);
        }
    };


    const handleDeselectAll = async () => {
        setIsProcessing(true);
        try {
            const itemsToRemove = merchandise.filter(item => item.isAdded).map(item => item.id);

            if (itemsToRemove.length === 0) {
                console.error('No merchandise items to remove');
                setIsProcessing(false);
                return;
            }

            await axiosInstance.post(`/api/eventMerchandise/${eventId}/merchandise/bulk-remove`, {
                merchandiseIds: itemsToRemove
            });

            await fetchMerchandise();
        } catch (error) {
            console.error('Error removing all merchandise from event:', error);
        } finally {
            setIsProcessing(false);
        }
    };


    const handleModalClose = () => {
        setShowModal(false);
        fetchMerchandise();
    };

    const findNextAvailablePosition = () => {
        const assignedPositions = merchandise
            .filter(item => item.isAdded)
            .map(item => parseInt(item.placementPosition))
            .filter(pos => !isNaN(pos));

        if (assignedPositions.length === 0) return 1;

        const maxPosition = Math.max(...assignedPositions);
        for (let i = 1; i <= maxPosition + 1; i++) {
            if (!assignedPositions.includes(i)) {
                return i;
            }
        }
        return null; // This should never happen, but it's a safeguard
    };

    const filteredMerchandise = merchandise.filter(item =>
        item.item_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.item_number.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Manage Merchandise</h2>
                <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
            <div className="mb-4 flex space-x-4">
                <input
                    type="text"
                    className="flex-grow p-2 border border-gray-300 rounded-lg"
                    placeholder="Search Merchandise"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                />
                <button
                    className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={handleSelectAll}
                    disabled={isProcessing}
                >
                    {isProcessing ? 'Processing...' : 'Select All'}
                </button>
                <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                    onClick={handleDeselectAll}
                    disabled={isProcessing}
                >
                    {isProcessing ? 'Processing...' : 'Deselect All'}
                </button>
            </div>
            {isLoading ? (
                <div className="flex items-center justify-center h-full">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
            ) : (
                <div className="overflow-y-auto" style={{ maxHeight: '60vh' }}>
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                        <tr>
                            <th className="border px-4 py-2 text-center">Item Number</th>
                            <th className="border px-4 py-2">Name</th>
                            <th className="border px-4 py-2 text-center">Sell Price</th>
                            <th className="border px-4 py-2 text-center">Level</th>
                            <th className="border px-4 py-2 text-center">Placement Position</th>
                            <th className="border px-4 py-2 text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredMerchandise.map((item, index) => (
                            <tr key={item.id} className={item.isAdded ? "bg-gray-100" : ""}>
                                <td className="border px-4 py-2 text-center">{item.item_number}</td>
                                <td className="border px-4 py-2">{item.item_name}</td>
                                <td className="border px-4 py-2 text-center">${item.sell_price}</td>
                                <td className="border px-4 py-2 text-center">{item.item_level}</td>
                                <td className="border px-4 py-2 text-center">
                                    {item.placementPosition !== 'Not assigned' ? item.placementPosition : 'Not assigned'}
                                </td>
                                <td className="border px-4 py-2 text-center">
                                    <button
                                        className="text-primary hover:text-orange-700 transition duration-300"
                                        onClick={() =>
                                            item.isAdded
                                                ? handleRemoveMerchandiseFromEvent(item.id)
                                                : handleAddMerchandiseToEvent(item.id)
                                        }
                                        disabled={isProcessing}
                                    >
                                        {item.isAdded ? (
                                            <i className="fas fa-times text-red-500"></i>
                                        ) : (
                                            <i className="fas fa-plus text-green-500"></i>
                                        )}
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
            {showModal && (
                <AddMerchandise
                    merchandise={currentMerchandise}
                    onClose={handleModalClose}
                    onSave={handleModalClose}
                />
            )}
        </div>
    );
}

export default MerchandiseList;

