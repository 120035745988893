import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import AddEvent from "./AddEvent";

function Events() {
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editEvent, setEditEvent] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const toggleDropdown = (eventId, e) => {
        e.stopPropagation(); // Prevent the event from bubbling up
        setDropdownVisible((prevState) => ({
            ...prevState,
            [eventId]: !prevState[eventId],
        }));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.relative')) {
                setDropdownVisible({});
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const res = await axiosInstance.get('/api/events');
                setEvents(res.data);
                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching events:', err);
                setIsLoading(false);
            }
        };
        fetchEvents();
    }, []);

    const handleAddEvent = async (event) => {
        try {
            if (editEvent) {
                const res = await axiosInstance.put(`/api/events/${editEvent.id}`, event);
                setEvents(events.map(e => e.id === editEvent.id ? res.data : e));
            } else {
                const res = await axiosInstance.post('/api/events', event);
                setEvents([...events, res.data]);
            }
            setShowModal(false);
            setEditEvent(null);
            setDropdownVisible({});
        } catch (err) {
            console.error('Error adding/updating event:', err);
            if (err.response && err.response.data && err.response.data.message) {
                alert(`Error: ${err.response.data.message}`);
            } else {
                alert('An error occurred while saving the event. Please try again.');
            }
            setDropdownVisible({});

        }
    };

    const handleDeleteEvent = async (eventId, e) => {
        e.stopPropagation(); // Prevent the event from bubbling up
        try {
            await axiosInstance.delete(`/api/events/${eventId}`);
            setEvents(events.filter(event => event.id !== eventId));
            setDropdownVisible({});
        } catch (err) {
            console.error('Error deleting event:', err);
        }
    };

    const handleEventClick = (eventId) => {
        navigate(`/event-details/${eventId}`);
    };

    const handleEditClick = (event, e) => {
        e.stopPropagation(); // Prevent the event from bubbling up
        setEditEvent(event);
        setShowModal(true);
        setDropdownVisible({});
    };

    const filteredEvents = events.filter(event =>
        event.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.notes.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
        );
    }

    return (
        <div className="flex h-screen">
            <div className="flex-grow p-4">
                <h2 className="text-3xl font-bold mb-4">Events</h2>
{                /*<div className="flex justify-between mb-4">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="p-2 border border-gray-300 rounded-lg"
                    />
                    <button
                        className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                        onClick={() => {
                            setEditEvent(null);
                            setShowModal(true);
                        }}
                    >
                        Add Event
                    </button>
                </div>*/}
                <div className={events.length >= 10 ? 'overflow-x-auto' : ''}>
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md text-center">
                        <thead>
                        <tr className="bg-gray-100">
                            <th className="border p-2">Event Name</th>
                            <th className="border p-2">Description</th>
                            <th className="border p-2">Notes</th>
                            <th className="border p-2">Number of Participants</th>
                            <th className="border p-2">Practice Runs per Participant</th>
                            <th className="border p-2">Duration per Participant (Seconds)</th>
                            <th className="border p-2">Average Cart Value ($)</th>
                            <th className="border p-2">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredEvents.map(event => (
                            <tr key={event.id} className="hover:bg-gray-50 transition-colors duration-300"
                                onClick={() => handleEventClick(event.id)}>
                                <td className="border px-4 py-2">{event.name}</td>
                                <td className="border px-4 py-2">{event.description}</td>
                                <td className="border px-4 py-2">{event.notes}</td>
                                <td className="border px-4 py-2">{event.participants ? event.participants.length : 0}</td>
                                <td className="border px-4 py-2">{event.practice_sessions}</td>
                                <td className="border px-4 py-2">{event.duration}</td>
                                <td className="border px-4 py-2">{event.average_cart_value}</td>
                                <td className="border px-4 py-2 relative">
                                    <button className="text-primary hover:text-orange-700 transition duration-300"
                                        onClick={(e) => toggleDropdown(event.id, e)}>⚙️
                                    </button>
                                    {dropdownVisible[event.id] && (
                                        <div
                                            className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg bg-opacity-100 z-50">
                                            <button
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition duration-300"
                                                onClick={(e) => handleEditClick(event, e)}
                                            >
                                                Edit
                                            </button>
                                            {/* <button
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition duration-300"
                                                onClick={(e) => handleDeleteEvent(event.id, e)}
                                            >
                                                Delete
                                            </button>*/}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {showModal && (
                    <AddEvent
                        onClose={() => {
                            setShowModal(false);
                            setEditEvent(null);
                        }}
                        onSave={handleAddEvent}
                        editEvent={editEvent}
                    />
                )}
            </div>
        </div>
    );
}

export default Events;