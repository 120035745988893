// client/src/components/AddMerchandise.js

import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from '../utils/axiosInstance';

function AddMerchandise({ onClose, onSave, editMerchandise }) {
    const initialState = useMemo(() => ({
        item_number: '',
        item_name: '',
        sell_price: '',
        item_level: '',
        image_url: ''
    }), []);

    const [merchandise, setMerchandise] = useState(initialState);
    const [imageFile, setImageFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (editMerchandise) {
            setMerchandise(editMerchandise);
            if (editMerchandise.image_url) {
                setImageFile(null);
            }
        } else {
            setMerchandise(initialState);
        }
    }, [editMerchandise, initialState]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMerchandise(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
        setMerchandise(prevState => ({
            ...prevState,
            image_url: URL.createObjectURL(file)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUploading(true);

        try {
            let merchandiseData = {
                item_number: merchandise.item_number,
                item_name: merchandise.item_name,
                sell_price: merchandise.sell_price,
                item_level: merchandise.item_level,
            };

            let newMerchandiseID;

            if (editMerchandise) {
                // Update existing merchandise
                await axiosInstance.put(`/api/merchandise/${editMerchandise.id}`, merchandiseData);
                newMerchandiseID = editMerchandise.id;
            } else {
                // Create new merchandise
                const createRes = await axiosInstance.post('/api/merchandise', merchandiseData);
                newMerchandiseID = createRes.data.id;
            }

            // If there's an image file, upload it
            if (imageFile) {
                const formData = new FormData();
                formData.append('image', imageFile);
                formData.append('merchandiseID', newMerchandiseID);

                await axiosInstance.post('/api/upload/merchandise-image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }

            onSave();
            onClose();
            setImageFile(null);
        } catch (err) {
            console.error('Error saving merchandise:', err.response?.data || err.message);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4">{editMerchandise ? 'Edit Merchandise' : 'Add Merchandise'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Item Number</label>
                        <input
                            type="text"
                            name="item_number"
                            value={merchandise.item_number}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Name</label>
                        <input
                            type="text"
                            name="item_name"
                            value={merchandise.item_name}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Sell Price</label>
                        <input
                            type="text"
                            name="sell_price"
                            value={merchandise.sell_price}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Level</label>
                        <select
                            name="item_level"
                            value={merchandise.item_level}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-lg"
                            required
                        >
                            <option value="">Select Level</option>
                            <option value="Small">Small</option>
                            <option value="Medium">Medium</option>
                            <option value="Large">Large</option>
                            <option value="Premium">Premium</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Image</label>
                        {editMerchandise && merchandise.image_url && (
                            <div className="mb-2">
                                <a href={merchandise.image_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline break-all">
                                    {merchandise.image_url}
                                </a>
                            </div>
                        )}
                        <input type="file" name="image" onChange={handleImageChange} className="w-full p-2 border border-gray-300 rounded-lg" />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                            disabled={isUploading}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-orange-700 transition duration-300"
                            disabled={isUploading}
                        >
                            {editMerchandise ? 'Update Merchandise' : 'Add Merchandise'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddMerchandise;

