import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import AddParticipant from './AddParticipant';
import UploadXlsx from './UploadXlsx';
import ParticipantDetails from './ParticipantDetails';

function ParticipantsList({ participants: initialParticipants, onClose, eventId }) {
    const [participants, setParticipants] = useState(initialParticipants || []);
    const [searchQuery, setSearchQuery] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState({});
    const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showParticipantDetails, setShowParticipantDetails] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [duplicates, setDuplicates] = useState([]);
    const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = useState(false);
    const [confirmText, setConfirmText] = useState('');

    useEffect(() => {
        if (!initialParticipants) {
            const fetchParticipants = async () => {
                setIsLoading(true);
                try {
                    const res = await axiosInstance.get(`/api/participants/${eventId}/participants`);
                    setParticipants(res.data);
                    setIsLoading(false);
                } catch (err) {
                    console.error(err);
                    setIsLoading(false);
                }
            };
            fetchParticipants();
        }
    }, [eventId, initialParticipants]);

    const toggleDropdown = (participantId) => {
        setDropdownVisible((prevState) => ({
            ...prevState,
            [participantId]: !prevState[participantId],
        }));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.relative')) {
                setDropdownVisible({});
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleEditParticipant = (participant) => {
        setSelectedParticipant(participant);
        setShowAddParticipantModal(true);
        setDropdownVisible({});
    };

    const handleDeleteParticipant = async (participantId) => {
        try {
            await axiosInstance.delete(`/api/participants/${participantId}`);
            setParticipants((prevParticipants) =>
                prevParticipants.filter((participant) => participant.id !== participantId)
            );
            setDropdownVisible({});
        } catch (err) {
            console.error('Error deleting participant:', err);
        }
    };

    const handleViewDetails = async (participantId) => {
        try {
            const res = await axiosInstance.get(`/api/participants/${eventId}/participants/${participantId}`);
            setSelectedParticipant(res.data);
            setShowParticipantDetails(true);
        } catch (err) {
            console.error('Error retrieving participant details:', err);
        }
    };

    const handleFileUploadSuccess = (duplicates) => {
        const fetchParticipants = async () => {
            setIsLoading(true);
            try {
                const res = await axiosInstance.get(`/api/participants/${eventId}/participants`);
                setParticipants(res.data);
                setIsLoading(false);
                setUploadSuccess(true);
                setDuplicates(duplicates || []);
            } catch (err) {
                console.error(err);
                setIsLoading(false);
            }
        };
        fetchParticipants();
    };

    const handleAddParticipant = async (newParticipant) => {
        try {
            let res;
            if (selectedParticipant) {
                // Update existing participant
                res = await axiosInstance.put(`/api/participants/${eventId}/participants/${selectedParticipant.id}`, newParticipant);
            } else {
                // Add new participant
                res = await axiosInstance.post(`/api/participants/${eventId}/participants`, newParticipant);
            }
            setParticipants((prevParticipants) => {
                if (selectedParticipant) {
                    return prevParticipants.map((participant) =>
                        participant.id === selectedParticipant.id ? res.data : participant
                    );
                }
                return [...prevParticipants, res.data];
            });
            setShowAddParticipantModal(false);
        } catch (err) {
            console.error('Error adding participant:', err);
        }
    };


    const handleDeleteAllParticipants = async () => {
        if (confirmText.toLowerCase() === 'confirm') {
            try {
                await axiosInstance.delete(`/api/participants/${eventId}/participants`);
                setParticipants([]);
                setShowDeleteAllConfirmation(false);
                setConfirmText('');
            } catch (err) {
                console.error('Error deleting all participants:', err);
            }
        }
    };

    const filteredParticipants = participants.filter((participant) =>
        (participant.first_name?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
        (participant.last_name?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
        (participant.address_line_1?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
        (participant.email?.toLowerCase().includes(searchQuery.toLowerCase()) || '') ||
        (participant.day_phone?.toLowerCase().includes(searchQuery.toLowerCase()) || '')
    );

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
        );
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl h-5/6 overflow-auto">

                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold mb-4">Participants</h2>
                    <button
                        className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
                <div className="flex justify-between mb=4">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="p-2 border border-gray-300 rounded-lg"
                    />
                    <div className="space-x-4">
                        <button
                            className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                            onClick={() => {
                                setSelectedParticipant(null);
                                setShowAddParticipantModal(true);
                            }}
                        >
                            Add Participant
                        </button>
                        {!uploadSuccess && (
                            <button
                                className="bg-primary hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                                onClick={() => setShowUploadModal(true)}
                            >
                                Upload xlsx
                            </button>
                        )}
                        <button
                            className="bg-red-600 hover:bg-red-800 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                            onClick={() => setShowDeleteAllConfirmation(true)}
                        >
                            Delete All
                        </button>
                    </div>
                </div>
                <div className={participants.length >= 5 ? 'overflow-x-auto' : ''}>
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md text-center">
                        <thead>
                        <tr className="bg-gray-100">
                            <th className="border px-4 py-2">First Name</th>
                            <th className="border px-4 py-2">Last Name</th>
                            <th className="border px-4 py-2">ID</th>
                            <th className="border px-4 py-2">Address</th>
                            <th className="border px-4 py-2">Email</th>
                            <th className="border px-4 py-2">Phone number</th>
                            <th className="border px-4 py-2">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredParticipants.map((participant) => (
                            <tr
                                key={participant.id}
                                className="hover:bg-gray-50 transition-colors duration-300 cursor-pointer"
                                onClick={() => handleViewDetails(participant.id)} // Pass participant.id here
                            >
                                <td className="border px-4 py-2">{participant.first_name}</td>
                                <td className="border px-4 py-2">{participant.last_name}</td>
                                <td className="border px-4 py-2">{participant.id}</td>
                                <td className="border px-4 py-2">
                                    {participant.address_line_1} {participant.address_line_2} {participant.address_line_3} {participant.city}, {participant.state_code}, {participant.postal_code}, {participant.country_code}
                                </td>
                                <td className="border px-4 py-2">{participant.email}</td>
                                <td className="border px-4 py-2">{participant.day_phone}</td>
                                <td className="border px-4 py-2">
                                    <div className="relative">
                                        <button
                                            className="text-primary hover:text-orange-700 transition duration-300"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleDropdown(participant.id);
                                            }}
                                        >
                                            ⚙️
                                        </button>
                                        {dropdownVisible[participant.id] && (
                                            <div
                                                className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg bg-opacity-100 z-50"
                                                style={{zIndex: 1000}}
                                            >
                                                <button
                                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition duration-300"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditParticipant(participant);
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition duration-300"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteParticipant(participant.id);
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {duplicates.length > 0 && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4">
                        <strong className="font-bold">Duplicates Found:</strong>
                        <ul className="mt-2">
                            {duplicates.map((duplicate, index) => (
                                <li key={index}>
                                    {duplicate.duplicateData.email} already exists with event
                                    ID: {duplicate.existingParticipant.event_id}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {showAddParticipantModal && (
                    <AddParticipant
                        participantData={selectedParticipant}
                        onClose={() => setShowAddParticipantModal(false)}
                        onSave={handleAddParticipant}
                    />
                )}
                {showUploadModal && (
                    <div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                            <UploadXlsx
                                eventId={eventId}
                                onClose={() => setShowUploadModal(false)}
                                onSave={handleFileUploadSuccess}
                            />
                            <div className="flex justify-end mt-4">
                                <button
                                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                                    onClick={() => setShowUploadModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {showParticipantDetails && (
                    <ParticipantDetails participant={selectedParticipant}
                                        onClose={() => setShowParticipantDetails(false)} />
                )}
                {showDeleteAllConfirmation && (
                    <div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                            <p className="mb-4">Type "confirm" to delete all participants for this event.</p>
                            <input
                                type="text"
                                value={confirmText}
                                onChange={(e) => setConfirmText(e.target.value)}
                                className="p-2 border border-gray-300 rounded-lg w-full mb-4"
                            />
                            <div className="flex justify-between">
                                <button
                                    className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-800 transition duration-300"
                                    onClick={handleDeleteAllParticipants}
                                >
                                    Delete All
                                </button>
                                <button
                                    className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition duration-300"
                                    onClick={() => setShowDeleteAllConfirmation(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ParticipantsList;

