import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const exportAsImage = async (data) => {
    const exportElement = createExportElement(data);
    const canvas = await html2canvas(exportElement, {
        useCORS: true,
        allowTaint: true,
    });
    const image = canvas.toDataURL('image/png', 1.0);
    const link = document.createElement('a');
    link.href = image;
    link.download = 'export.png';
    link.click();

    // Clean up the DOM
    document.body.removeChild(exportElement);
};

export const exportAsPDF = async (data) => {
    const exportElement = createExportElement(data);
    const canvas = await html2canvas(exportElement, {
        useCORS: true,
        allowTaint: true,
    });
    const image = canvas.toDataURL('image/png', 1.0);
    const pdf = new jsPDF('p', 'pt', 'a4');
    const imgProps = pdf.getImageProperties(image);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(image, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('export.pdf');

    // Clean up the DOM
    document.body.removeChild(exportElement);
};

const createExportElement = (data) => {
    const { localMapImage, merchandise, assignedNumbers } = data;

    const container = document.createElement('div');
    container.style.position = 'relative';
    container.style.width = '100%';
    container.style.padding = '20px'; // Adding padding for better appearance

    const mapImg = document.createElement('img');
    mapImg.src = localMapImage;
    mapImg.alt = 'Map';
    mapImg.style.width = '100%';
    mapImg.style.borderRadius = '5px';
    mapImg.style.boxShadow = '0 2px 5px rgba(0,0,0,0.1)';

    container.appendChild(mapImg);

    const legendContainer = document.createElement('div');
    legendContainer.style.marginTop = '20px';

    const legend = `
        <style>
            .circle {
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-right: 5px;
            }
            .bg-green-500 { background-color: #48bb78; }
            .bg-yellow-500 { background-color: #ecc94b; }
            .bg-blue-500 { background-color: #4299e1; }
            .bg-purple-500 { background-color: #9f7aea; }
            .flex { display: flex; }
            .items-center { align-items: center; }
            .mb-2 { margin-bottom: 0.5rem; }
            .ml-2 { margin-left: 0.5rem; }
            .mt-4 { margin-top: 1rem; }
            .prize-legend span { margin-right: 20px; }
        </style>
        <div class="prize-legend mt-4">
            <span class="circle bg-green-500"></span> Small Prize
            <span class="circle bg-yellow-500 ml-4"></span> Medium Prize
            <span class="circle bg-blue-500 ml-4"></span> Large Prize
            <span class="circle bg-purple-500 ml-4"></span> Limited Prize
        </div>
        <div class="merchandise-list mt-4">
            <ol>
                ${merchandise.map(item => `
                    <li class="flex items-center mb-2">
                        <span class="circle ${getColorForItemLevel(item.item_level)}"></span>
                        <span class="ml-2">${assignedNumbers[item.id]}. ${item.item_name}</span>
                    </li>
                `).join('')}
            </ol>
        </div>
    `;

    legendContainer.innerHTML = legend;
    container.appendChild(legendContainer);

    document.body.appendChild(container);
    return container;
};

const getColorForItemLevel = (level) => {
    switch (level) {
        case 'Small':
            return 'bg-green-500';
        case 'Medium':
            return 'bg-yellow-500';
        case 'Large':
            return 'bg-blue-500';
        case 'Premium':
            return 'bg-purple-500';
        default:
            return 'bg-gray-500';
    }
};
