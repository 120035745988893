// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWrapper from './App';
import './tailwind.css';

ReactDOM.render(
    <React.StrictMode>
        <AppWrapper />
    </React.StrictMode>,
    document.getElementById('root')
);
